import React from "react";
import renderHTML from "../../../helpers/renderHTML";

const AwardsCertificatesSection = props => {
  const { title, data } = props;

  return (
    <div className="awards-certificates">
      <h3 className="awards-certificates__title" dangerouslySetInnerHTML={renderHTML(title)} />
      <div className="awards-certificates__wrapper">
        {data.map(item => (
          <span className="awards-certificates__logo">
            {item.url ? (
              <a href={item.url} target="_blank" rel="noreferrer">
                <img src={item.image.source_url} alt={item.image.title} className="lazyload" />
              </a>
            ) : (
              <img src={item.image.source_url} alt={item.image.title} className="lazyload" />
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AwardsCertificatesSection;

import React from "react";
import Technology from "./components/Technology";

const AboutTechnologies = props => {
  return (
    <section id="about__technologies" className="technologies">
      <div className="section__header about__page-header">
        <h2 className="section__title--large">Technologies</h2>
        <p className="section__title--small">
          Our team creates a whole and complements each other in technologies.
        </p>
      </div>
      <div className="about__technologies">
        <div className="container">
          <Technology type={props.data.backend} title="backend" />
          <Technology type={props.data.frontend} title="frontend" />
          <Technology type={props.data.server} title="Server / Hosting" />
          <Technology type={props.data.ux_design} title="ux / design" />
          <Technology type={props.data.tests} title="tests" />
        </div>
      </div>
    </section>
  );
};
export default AboutTechnologies;

import React from "react";
import Slider from "react-slick";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

import backgroundAfterWork1 from "../../images/afterwork/Afterwork3.jpg";
import backgroundAfterWork2 from "../../images/afterwork/Afterwork4.jpg";
import backgroundAfterWork3 from "../../images/afterwork/Afterwork1.jpg";
import backgroundAfterWork4 from "../../images/afterwork/Afterwork2.jpg";

class AfterWork2020 extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { imagesUrl: [], slideIndex: 0 };
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 5000,
      vertical: false,
      infinite: true,
      arrows: false,
      beforeChange: (oldIndex, newIndex) => {
        setTimeout(() => {
          this.setState({ slideIndex: newIndex / 3 });
        }, 10);
      },
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: false,
            infinite: false
          }
        }
      ]
    };

    return (
      <section id="about__afterwork" className="afterwork">
        <div className="section__header afterwork__header">
          <h2 className="section__title--large">After Work</h2>

          <p className="section__title--small">
            We make a great team,
            <br />
            not only in workspace!
          </p>
        </div>
        <div className="afterwork__container">
          <Slider className="afterwork__hexagons" {...settings}>
            {this.props.afterWorkContent.map(content => {
              // console.log(image.node.acf.gallery[0].item.source_url);
              return (
                <div>
                  <div>
                    <div
                      className="hexagon test"
                      style={{
                        backgroundImage: `url(${content.node.acf.gallery[0].item.localFile.childImageSharp.original.src})`
                      }}
                    >
                      <div className="hexTop" />
                      <div className="hexBottom" />
                    </div>

                    <p className="afterwork__title">{content.node.title}</p>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="afterwork__background">
            <div className="background__left">
              <div
                className="hexagon background__left--first"
                style={{
                  backgroundImage: `url(${backgroundAfterWork1})`
                }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className="hexagon background__left--second"
                style={{
                  backgroundImage: `url(${backgroundAfterWork2})`
                }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
            </div>
            <div className="background__right">
              <div
                className="hexagon background__right--first"
                style={{
                  backgroundImage: `url(${backgroundAfterWork1})`
                }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className="hexagon background__right--second"
                style={{
                  backgroundImage: `url(${backgroundAfterWork4})`
                }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className="hexagon background__right--third"
                style={{
                  backgroundImage: `url(${backgroundAfterWork3})`
                }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
            </div>
          </div>
          <ProgressBar slideIndex={this.state.slideIndex} />
          <div className="afterwork__button">
            <a href="/about/after-work/">
              <span className="btn__cta btn__cta--green btn__cta--arrow btn__cta--inverse">
                see more
              </span>
            </a>
          </div>
        </div>
      </section>
    );
  }
}
export default AfterWork2020;

import React from "react";

const scrollToRef = el =>
  window.scrollTo({
    top: el.offsetTop,
    behavior: "smooth"
  });
const Header = props => {
  const executeScroll = (e, id) => {
    e.preventDefault();
    const el = document.querySelector(`#${id}`);
    if (el) {
      scrollToRef(el);
    }
  };

  return (
    <section className="not-home-banner about-header">
      <div className="about-header__wrapper">
        <div className="about-header__item about-header__item--button">
          <a
            href="/"
            onClick={e => executeScroll(e, "about__whoweare")}
            className="btn__cta btn__cta--black btn__cta--arrow"
          >
            who we are
          </a>
        </div>
        <div className="about-header__item about-header__item--button">
          <a
            href="/"
            className="btn__cta btn__cta--black btn__cta--arrow"
            onClick={e => executeScroll(e, "about__team")}
          >
            team
          </a>
        </div>
        <div className="about-header__item">
          <h2 className="section__title--large">{props.title}</h2>
        </div>
        <div className="about-header__item about-header__item--button">
          <a
            href="/"
            onClick={e => executeScroll(e, "about__technologies")}
            className="btn__cta btn__cta--black btn__cta--arrow"
          >
            tech
          </a>
        </div>
        <div className="about-header__item about-header__item--button">
          <a
            href="/"
            onClick={e => executeScroll(e, "about__afterwork")}
            className="btn__cta btn__cta--black btn__cta--arrow"
          >
            after work
          </a>
        </div>
      </div>
    </section>
  );
};
export default Header;

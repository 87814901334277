import React from "react";
import Slider from "react-slick";
import renderHTML from "../../helpers/renderHTML";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

class Team extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { imagesUrl: [], slideIndex: 0 };
  }

  render() {
    const filterBoss = this.props.team.nodes.filter(member => member.worker_cat[0] === 7);
    const filterMarketing = this.props.team.nodes.filter(member => member.worker_cat[0] === 109);
    const filterPM = this.props.team.nodes.filter(member => member.worker_cat[0] === 108);
    const filterQA = this.props.team.nodes.filter(member => member.worker_cat[0] === 89);
    const filterFrontend = this.props.team.nodes.filter(member => member.worker_cat[0] === 8);
    const filterBackend = this.props.team.nodes.filter(member => member.worker_cat[0] === 9);

    const filteredWorkersMobile = [
      ...filterBoss,
      ...filterMarketing,
      ...filterPM,
      ...filterQA,
      ...filterFrontend,
      ...filterBackend
    ];

    let bossCount = 0;
    let marketingCount = 0;
    let pmCount = 0;
    let qaCount = 0;
    let frontendCount = 0;
    let backendCount = 0;

    const settings = {
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0,
      infinite: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ],
      beforeChange: (oldIndex, newIndex) => {
        setTimeout(() => {
          this.setState({ slideIndex: newIndex / 5 });
        }, 10);
      }
    };

    return (
      <section id="about__team" className="team">
        <div className="section__header team__header">
          <h2 className="section__title--large">Team</h2>

          <p className="section__title--small">
            We make a great team,
            <br />
            not only in workspace!
          </p>
        </div>

        <div className="team__container">
          <div className="team__hexagons team__hexagons--boss">
            <div className="hexagon hexagon__main hexagon--text hexagon--content">
              <p>
                <strong>BOSS</strong>
                <br />
                The heads of our agency. They care about quality.
              </p>
            </div>
            {filterBoss.map(worker => {
              bossCount += 1;
              return (
                <div
                  className={`hexagon hexagon--image hexagon--image-${bossCount}`}
                  style={{
                    backgroundImage: `url(${worker.acf.photo &&
                      worker.acf.photo.localFile.childImageSharp.original.src})`
                  }}
                >
                  <div className="hexTop" />
                  <div className="hexBottom" />
                  <div className="hexagon__name">
                    <p>{worker.title}</p>
                    <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="team__hexagons team__hexagons--marketing">
            <div className="hexagon hexagon__main hexagon--text hexagon--content">
              <p>
                <strong>marketing</strong>
                <br />
                Powerpoint is the main software for him.
              </p>
            </div>
            {filterMarketing.map(worker => {
              marketingCount += 1;
              return (
                <div
                  className={`hexagon hexagon--image hexagon--image-${marketingCount}`}
                  style={{
                    backgroundImage: `url(${worker.acf.photo &&
                      worker.acf.photo.localFile.childImageSharp.original.src})`
                  }}
                >
                  <div className="hexTop" />
                  <div className="hexBottom" />
                  <div className="hexagon__name">
                    <p>{worker.title}</p>
                    <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="team__hexagons team__hexagons--pm">
            <div className="hexagon hexagon__main hexagon--text hexagon--content">
              <p>
                <strong>PM’s</strong>
                <br />
                How is it a lot to talk and a little to do?
              </p>
            </div>
            {filterPM.map(worker => {
              pmCount += 1;

              return (
                <div
                  className={`hexagon hexagon--image hexagon--image-${pmCount}`}
                  style={{
                    backgroundImage: `url(${worker.acf.photo &&
                      worker.acf.photo.localFile.childImageSharp.original.src})`
                  }}
                >
                  <div className="hexTop" />
                  <div className="hexBottom" />
                  <div className="hexagon__name">
                    <p>{worker.title}</p>
                    <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="team__hexagons team__hexagons--qa">
            <div className="hexagon hexagon__main hexagon--text hexagon--content">
              <p>
                <strong>Testers</strong>
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                We'll break a leg for you, so you don't have to
              </p>
            </div>
            {filterQA.map(worker => {
              qaCount += 1;

              return (
                <div
                  className={`hexagon hexagon--image hexagon--image-${qaCount}`}
                  style={{
                    backgroundImage: `url(${worker.acf.photo &&
                      worker.acf.photo.localFile.childImageSharp.original.src})`
                  }}
                >
                  <div className="hexTop" />
                  <div className="hexBottom" />
                  <div className="hexagon__name">
                    <p>{worker.title}</p>
                    <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="team__hexagons team__hexagons--frontend">
            <div className="hexagon hexagon__main hexagon--text hexagon--content">
              <p>
                <strong>Frontend</strong>
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                You don’t need to know how, but it’s working and look
              </p>
            </div>
            {filterFrontend.map(worker => {
              frontendCount += 1;

              return (
                <div
                  className={`hexagon hexagon--image hexagon--image-${frontendCount}`}
                  style={{
                    backgroundImage: `url(${worker.acf.photo &&
                      worker.acf.photo.localFile.childImageSharp.original.src})`
                  }}
                >
                  <div className="hexTop" />
                  <div className="hexBottom" />
                  <div className="hexagon__name">
                    <p>{worker.title}</p>
                    <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="team__hexagons team__hexagons--backend">
            <div className="hexagon hexagon__main hexagon--text hexagon--content">
              <p>
                <strong>Backend</strong>
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                You don’t need to know how, but it’s working!
              </p>
            </div>
            {filterBackend.map(worker => {
              backendCount += 1;
              return (
                <div
                  className={`hexagon hexagon--image hexagon--image-${backendCount}`}
                  style={
                    worker.acf.photo && {
                      backgroundImage: `url(${worker.acf.photo &&
                        worker.acf.photo.localFile.childImageSharp.original.src})`
                    }
                  }
                >
                  <div className="hexTop" />
                  <div className="hexBottom" />
                  <div className="hexagon__name">
                    <p>{worker.title}</p>
                    <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                  </div>
                </div>
              );
            })}
          </div>
          <a href="/join-us" className="join-us-hexagon">
            <div className="team__hexagons team__hexagons--joinus">
              <div className="hexagon--joinus hexagon--content">
                <p>
                  <strong>YOU!</strong>
                  <br />
                  Choose one of the departments and take your place in it!
                  <br />
                  <span className="hexagon__arrow" />
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="team__mobile">
          <Slider className="slider__certificates" {...settings}>
            {filteredWorkersMobile.map(worker => {
              return (
                <div>
                  <div>
                    <div
                      className="hexagon"
                      style={
                        worker.acf.photo && {
                          backgroundImage: `url(${worker.acf.photo &&
                            worker.acf.photo.localFile.childImageSharp.original.src})`
                        }
                      }
                    >
                      <div className="hexTop" />
                      <div className="hexBottom" />
                    </div>
                    <div className="team__name">
                      <p>{worker.title}</p>
                      <p dangerouslySetInnerHTML={renderHTML(worker.acf.position)} />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          <ProgressBar slideIndex={this.state.slideIndex} />
        </div>
      </section>
    );
  }
}
export default Team;

import React from "react";

import AwardsCertificatesSection from "./components/AwardsCertificatesSection";

const AwardsCertificates = props => {
  const { awards, certificates } = props;
  console.log(awards, certificates);

  return (
    <section id="about__awards-certificates">
      <div className="section__header about__page-header">
        <h2 className="section__title--large">Awards & Certificates</h2>
        <p className="section__title--small">
          Thanks to the engagement of the whole Panda Team we are proud to present.
        </p>
      </div>
      <div className="about__awards-certificates">
        <div className="container">
          <AwardsCertificatesSection title="Awards" data={awards} />
          <AwardsCertificatesSection title="Certificates" data={certificates} />
        </div>
      </div>
    </section>
  );
};
export default AwardsCertificates;

import React from "react";
import renderHTML from "../../helpers/renderHTML";

const scrollToRef = el =>
  window.scrollTo({
    top: el.offsetTop - 65,
    behavior: "smooth"
  });

class WhoWeAre extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imagesUrl: [] };
  }

  componentDidMount() {
    const { workersImages, afterWorkImages } = this.props;
    // eslint-disable-next-line no-unused-vars
    const workerImagesFiltered = workersImages.filter(
      image =>
        image.node.acf.photo &&
        image.node.acf.photo.source_url !==
          "https://wp.pandagroup.co/wp-content/uploads/2017/06/panda-blank.png"
    );
    const workerImagesUrl = workerImagesFiltered.map(
      image => image.node.acf.photo.localFile.childImageSharp.original.src
    );

    const afterWorkImagesUrl = afterWorkImages.map(
      image => image.node.acf.gallery[0].item.localFile.childImageSharp.original.src
    );

    function shuffleArray(array) {
      let i = array.length - 1;
      // eslint-disable-next-line no-plusplus
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        // eslint-disable-next-line no-param-reassign
        array[i] = array[j];
        // eslint-disable-next-line no-param-reassign
        array[j] = temp;
      }
      return array;
    }

    this.setState({
      imagesUrl: shuffleArray([...workerImagesUrl, ...afterWorkImagesUrl]),
      changedImage: 0
    });

    const self = this;
    setInterval(() => {
      const imageToShuffle = self.state.imagesUrl;
      const imagesShuffled = shuffleArray([...imageToShuffle]);

      let idx = 0;
      setInterval(() => {
        if (idx < imagesShuffled.length) {
          imageToShuffle[idx] = imagesShuffled[idx];
          self.setState({
            imagesUrl: imageToShuffle,
            changedImage: idx
          });
          idx += 1;
        }
      }, 150);
    }, 10000);
  }

  render() {
    const executeScroll = (e, id) => {
      e.preventDefault();
      const el = document.querySelector(`#${id}`);
      if (el) {
        scrollToRef(el);
      }
    };

    return (
      <section id="about__whoweare" className="whoweare">
        <div className="section__header whoweare__header">
          <h2 className="section__title--large">Who we are?</h2>

          <p className="section__title--small">
            We make a great team,
            <br /> not only in workspace!
          </p>
        </div>
        <div className="whoweare__content">
          <div className="whoweare__hexagons">
            <div className="hexagons__row hexagons__row hexagons__row--first">
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 3 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[3]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 6 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[6]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 8 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[8]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div className="hexagon hexagon--text">
                <span>professionalism</span>
              </div>
            </div>
            <div className="hexagons__row hexagons__row--second">
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 1 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[1]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 4 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[4]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div className="hexagon hexagon--text">
                <span>honesty</span>
              </div>
              <div className="hexagon hexagon--text">
                <span>teamwork</span>
              </div>
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 9 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[9]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
            </div>
            <div className="hexagons__row hexagons__row--third">
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 2 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[2]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 5 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[5]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div
                className={`hexagon hexagon--image ${
                  this.state.changedImage === 7 ? "hexagon hexagon--changed" : ""
                }`}
                style={{ backgroundImage: `url(${this.state.imagesUrl[7]})` }}
              >
                <div className="hexTop" />
                <div className="hexBottom" />
              </div>
              <div className="hexagon hexagon--text">
                <span>Passion</span>
              </div>
            </div>
          </div>
          <div className="whoweare__text">
            <p dangerouslySetInnerHTML={renderHTML(this.props.text)} />
            <a href="/" onClick={e => executeScroll(e, "about__team")} className="whoweare__button">
              <span className="btn__cta btn__cta--green btn__cta--arrow btn__cta--inverse">
                meet our people
              </span>
            </a>
          </div>
        </div>
      </section>
    );
  }
}
export default WhoWeAre;

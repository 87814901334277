import React, { PureComponent } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../Layout/Layout";

import Header from "../modules/About/Header";
import WhoWeAre from "../modules/About/WhoWeAre";
import Team from "../modules/About/Team";
import AwardsCertificates from "../modules/About/AwardsCertificates";
import Technologies from "../modules/About/Technologies";
import AfterWork2020 from "../modules/About/AfterWork2020";
import SEO from "../Layout/Seo";

class PageAboutTemplate2020 extends PureComponent {
  render() {
    const currentPage = this.props.data.wordpressPage;
    const workersImage = this.props.data.allWordpressWpWorker.edges;
    const afterWorkImage = this.props.data.allWordpressWpAfterwork.edges;
    const team = this.props.data.allWordpressWpWorker;
    const metaTitle = currentPage.yoast.title || "";
    const metaDesc = currentPage.yoast.metadesc || "";
    const metaKeyWords = currentPage.yoast.metakeywords || "";

    return (
      <Layout>
        <Helmet
          bodyAttributes={{
            class: "aboutpage"
          }}
        />
        <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
        <Header title={currentPage.title} />
        <WhoWeAre
          text={currentPage.acf.whoweare_text}
          workersImages={workersImage}
          afterWorkImages={afterWorkImage}
        />
        <section className="main-cta">
          <div className="container">
            <div className="section__header">
              <div className="section__title section__title--contact">
                Would you like to work with us?
              </div>
            </div>
            <div className="section__button">
              <div className="cta__content cta__content--what-we-can-do">
                <div className="join-us-button">
                  <a href="/join-us">
                    <div className="btn__cta btn__cta--green btn__cta--joinus">Join us</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Team team={team} />
        <section className="main-cta main-cta--mobile">
          <div className="container">
            <div className="section__header">
              <div className="section__title section__title--contact">
                Would you like to work with us?
              </div>
            </div>
            <div className="section__button">
              <div className="cta__content cta__content--what-we-can-do">
                <div className="join-us-button">
                  <a href="/join-us">
                    <div className="btn__cta btn__cta--green btn__cta--joinus">Join us</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AwardsCertificates
          awards={currentPage.acf.awards}
          certificates={currentPage.acf.certificates}
        />
        <Technologies data={currentPage.acf} />
        <AfterWork2020 afterWorkContent={afterWorkImage} />
      </Layout>
    );
  }
}

export default PageAboutTemplate2020;

export const pageQuery = graphql`
  query currentAbout2020PageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      acf {
        whoweare_text
        awards {
          url
          image {
            source_url
            title
          }
        }
        certificates {
          url
          image {
            source_url
            title
          }
        }
        backend {
          technology
          image {
            wordpress_id
            title
            caption
            description
            mime_type
          }
        }
        frontend {
          technology
          image {
            wordpress_id
            title
            caption
            description
            mime_type
          }
        }
        server {
          technology
          image {
            wordpress_id
            title
            caption
            description
            mime_type
          }
        }
        tests {
          technology
          image {
            wordpress_id
            title
            caption
            description
            mime_type
          }
        }
        ux_design {
          technology
          image {
            wordpress_id
            title
            caption
            description
            mime_type
          }
        }
      }
      yoast {
        title
        metakeywords
        metadesc
      }
    }
    allWordpressWpWorker {
      edges {
        node {
          id
          acf {
            photo {
              source_url
              link
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpAfterwork {
      edges {
        node {
          id
          title
          acf {
            gallery {
              item {
                source_url
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpWorker {
      nodes {
        id
        title
        acf {
          position
          photo {
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          photo_hover {
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        worker_cat
      }
    }
  }
`;

/* eslint-disable no-unused-vars */
import React from "react";
import backendImage1 from "../../../images/technologies/backend/Magento.png";
import backendImage2 from "../../../images/technologies/backend/PHP.png";
import backendImage3 from "../../../images/technologies/backend/MySQL.png";
import backendImage4 from "../../../images/technologies/backend/Composer.png";

import frontendImage1 from "../../../images/technologies/frontend/html5.png";
import frontendImage2 from "../../../images/technologies/frontend/css3.png";
import frontendImage3 from "../../../images/technologies/frontend/sass.jpg";
import frontendImage4 from "../../../images/technologies/frontend/es6.png";
import frontendImage5 from "../../../images/technologies/frontend/ts.png";
import frontendImage6 from "../../../images/technologies/frontend/knockout.png";
import frontendImage7 from "../../../images/technologies/frontend/vuejs.png";
import frontendImage8 from "../../../images/technologies/frontend/react.png";
import frontendImage9 from "../../../images/technologies/frontend/redux.jpg";
import frontendImage10 from "../../../images/technologies/frontend/gulp.png";
import frontendImage11 from "../../../images/technologies/frontend/webpack.png";
import frontendImage12 from "../../../images/technologies/frontend/PWA.jpg";

import serverImage1 from "../../../images/technologies/server/amazon-services.png";
import serverImage2 from "../../../images/technologies/server/varnish-bunny.jpg";
import serverImage3 from "../../../images/technologies/server/Nginx.png";
import serverImage4 from "../../../images/technologies/server/memcached.png";
import serverImage5 from "../../../images/technologies/server/redis.png";
import serverImage6 from "../../../images/technologies/server/docker.png";

import uxImage1 from "../../../images/technologies/ux/ps.jpg";
import uxImage2 from "../../../images/technologies/ux/uxpin.png";
import uxImage3 from "../../../images/technologies/ux/logoHotjar.png";
import uxImage4 from "../../../images/technologies/ux/heatmapme.png";
import uxImage5 from "../../../images/technologies/ux/ga_logo.svg";

import testsImage1 from "../../../images/technologies/tests/python.png";
import testsImage2 from "../../../images/technologies/tests/selenium.png";
import testsImage3 from "../../../images/technologies/tests/jenkins2.png";
import testsImage4 from "../../../images/technologies/tests/jmeter.svg";

const Technology = props => {
  const technology = props.type.filter(tech => tech.image !== null);
  let itemCurrent = 0;
  const backendLocalImages = ["", backendImage1, backendImage2, backendImage3, backendImage4];
  const frontendLocalImages = [
    "",
    frontendImage1,
    frontendImage2,
    frontendImage3,
    frontendImage4,
    frontendImage5,
    frontendImage6,
    frontendImage7,
    frontendImage8,
    frontendImage9,
    frontendImage10,
    frontendImage11,
    frontendImage12
  ];

  const serverLocalImages = [
    "",
    serverImage1,
    serverImage2,
    serverImage3,
    serverImage4,
    serverImage5,
    serverImage6
  ];

  const testsLocalImages = ["", testsImage1, testsImage2, testsImage3, testsImage4];

  const uxLocalImages = ["", uxImage1, uxImage2, uxImage3, uxImage4, uxImage5];

  return (
    <div className="technologies">
      <h3 className="technologies__title">{props.title}</h3>
      <div className="technologies__wrapper">
        {technology.map(option => {
          itemCurrent += 1;
          if (props.title === "backend") {
            return (
              <span className="technology__logo">
                <img
                  src={backendLocalImages[itemCurrent]}
                  alt={option.image.alt}
                  className="lazyload"
                />
              </span>
            );
          }
          if (props.title === "frontend") {
            return (
              <span className="technology__logo">
                <img
                  src={frontendLocalImages[itemCurrent]}
                  alt={option.image.alt}
                  className="lazyload"
                />
              </span>
            );
          }
          if (props.title === "Server / Hosting") {
            return (
              <span className="technology__logo">
                <img
                  src={serverLocalImages[itemCurrent]}
                  alt={option.image.alt}
                  className="lazyload"
                />
              </span>
            );
          }
          if (props.title === "ux / design") {
            return (
              <span className="technology__logo">
                <img src={uxLocalImages[itemCurrent]} alt={option.image.alt} className="lazyload" />
              </span>
            );
          }
          if (props.title === "tests") {
            return (
              <span className="technology__logo">
                <img
                  src={testsLocalImages[itemCurrent]}
                  alt={option.image.alt}
                  className="lazyload"
                />
              </span>
            );
          }
          return <></>;
        })}
      </div>
    </div>
  );
};
export default Technology;
